import "./MarketingPreferencesLayout.css";

import { Cms, useCmsData } from "CmsContext";
import { MessageKey, useI18n } from "_shared/hooks/I18n";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { FieldValues } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { containsNewsletterSubscriptionInSubscribedStatus, hasNewsletterSubscriptions, useAccount } from "../Account";
import { AccountSchema, gigyaWithPromise, isGigyaErrorResponse, useGigya } from "../GigyaContext";
import { useMetaData } from "../MetaDataContext";
import { replaceEmptyStringsWithNull } from "../_shared/Utils";
import { AdobeTagManagerComponent } from "../_shared/components/AdobeTagManagerComponent";
import { CDNResponsiveImage } from "../_shared/components/CDNResponsiveImage";
import { Meta } from "../_shared/components/Meta";
import { UserCentricsComponent } from "../_shared/components/UserCentricsComponent";
import { ConfirmOverlay } from "../_shared/components/confirmoverlay";
import { normalizeProfileFormsInFormData } from "../hooks/GigyaSchema";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { CmsTabStatus, useTabsStatus } from "./components/MarketingPreferencesTabStatus";
import { Optin } from "./components/Optin";
import { PreferenceTab, PreferenceTabFormFieldValues, PreferenceTabHandle } from "./components/PreferenceTab";
import { ScrollSlider } from "./components/ScrollSlider";
import { Snackbar } from "./components/Snackbar";
import { ProductReminderDeletionFailedError, useProductReminderContext } from "./components/productreminder";
import { merge } from "lodash";

type ConfirmOverlayOption = 'Newsletters' | 'ProductReminders' | 'All';
interface ConfirmOverlayCallback {
    promiseResolver: (confirmed: boolean) => void,
    option: ConfirmOverlayOption
}

interface AddDataFromTabsAction {
    type: "add",
    data: PreferenceTabFormFieldValues
}

interface ResetDataFromTabsAction {
    type: "reset"
}

type DataFromTabsAction = AddDataFromTabsAction | ResetDataFromTabsAction;

export const MarketingPreferencesLayout = () => {
    const {cmsData} = useCmsData();
    const { getMessage } = useI18n();
    const {isGigyaReady, gigya, getSchema} = useGigya();
    const { hash } = useLocation();
    const {account, refreshAccount} = useAccount();
    const navigate = useNavigate();

    const tabsStatus = useTabsStatus(cmsData?.components?.preferenceCenter?.tabs);

    const [activeTab, setActiveTab] = useState(() => {
        // search active tab from URL anchor (i.e., #data)
        let activeTab = cmsData ? cmsData.components.preferenceCenter.tabs[0] : null;
        if(cmsData){
            cmsData.components.preferenceCenter.tabs.map((item,_idx) => {
                if(hash && hash.substring(1) === item.anchor) {
                    activeTab = item;
                }
                return item;
            });
        }
        return activeTab;
    });

    const tabStatusOfActiveTab = activeTab ? tabsStatus.get(activeTab) : undefined;

    const { productReminderContext, deleteMarkedProductReminders, reloadProductReminders } = useProductReminderContext();

    const formRef = useRef<PreferenceTabHandle>(null);
    const scrollAnchorRef = useRef<HTMLDivElement>(null);

    const [gigyaSchema, setGigyaSchema] = useState<AccountSchema>();
    const [snackbarVisibility, setSnackbarVisibility] = useState(false);
    const [globalErrorMessage, setGlobalErrorMessage] = useState("");
    const [confirmOverlayCallback, setConfirmOverlayCallback] = useState<ConfirmOverlayCallback | undefined>();

    const [showClickedOnDisabledTabDialog, setShowClickedOnDisabledTabDialog] = useState(false)

    const [dataFromTabs, applyDataFromTabsAction] = useReducer(
        (state: PreferenceTabFormFieldValues, action: DataFromTabsAction) => {
            if (action.type === "add") {
                return merge(state, action.data);
            }
            return {};
        },
        {});

    useMemo(() => {
        // effect reloads the active tab if cmsData is changed (i.e., language switch)
        //
        if (cmsData && !!cmsData.components.preferenceCenter.tabs.length) {
            const activeTabForMe = cmsData.components.preferenceCenter.tabs.find(tab => tab.anchor === activeTab?.anchor) ?? cmsData.components.preferenceCenter.tabs[0]
            setActiveTab(activeTabForMe);
        }
    }, [cmsData, activeTab?.anchor]);

    useEffect(() => {
        if (!isGigyaReady()) {
            return;
        }
        getSchema!().then(setGigyaSchema); // was ist denn das?
    }, [isGigyaReady, getSchema]);

    const setActiveTabWithHistory = (validateAndSaveIntoState:boolean) => (tab: Cms.Tab) => {
        const tabHandle = formRef.current;
        if (!tabHandle) {
            return;
        }

        const validRun = () => {
            setActiveTab(tab);
            window.history.replaceState({}, tab.label, document.URL.replace(/#.*$/, "") + "#" + tab.anchor);
        }

        if (validateAndSaveIntoState) {
            tabHandle.validateAll()
                .then(() => tabHandle.getData())
                .then(d => applyDataFromTabsAction({ type: "add", data: d }))
                .then(validRun)
                .catch(_ => console.error);

        } else {
            validRun();
        }
    }

    const onTabClickHandler = (tab: Cms.Tab) => {
        if (tabsStatus.get(tab)?.enabled) {
            setActiveTabWithHistory(!!tabStatusOfActiveTab?.enabled)(tab);
        } else {
            setShowClickedOnDisabledTabDialog(!!tabStatusOfActiveTab?.enabled);
        }
    }

    const showNextTabAfterSaving = function () {
        if (tabsStatus.size === 0) {
            return;
        }

        let active: CmsTabStatus | undefined;
        if (!activeTab) {
            active = tabsStatus.values().next().value;
        } else {
            const tabsStatusArray = Array.from(tabsStatus.values());
            const currentTabIndex = tabsStatusArray.map(s => s.tab).indexOf(activeTab);
            active = tabsStatusArray.find((tabStatus, index) => {
                return tabStatus.enabled && index > currentTabIndex;
            });
            if (!active) {
                active = tabsStatusArray.find((tabStatus, index) => {
                    return tabStatus.enabled;
                })
            }
        }
        
        if (active) {
            setActiveTabWithHistory(false)(active.tab);
            window.scrollBy({
                "top": scrollAnchorRef.current ? scrollAnchorRef.current.getBoundingClientRect().top - 20 : 0,
                "left": 0,
                "behavior": "smooth"
            });
        }
    }

    if (tabStatusOfActiveTab && !tabStatusOfActiveTab.enabled) {
        showNextTabAfterSaving();
        return(<></>);
    }

    if (!cmsData || !gigyaSchema || !gigya) {
        return(<></>);
    }

    const validateAndSend = (formData: FieldValues) => {
        formRef.current!.validateAll()
            .then(obj => performSetAccountInfo(merge(merge(dataFromTabs, obj), formData)));
    }

    const performSetAccountInfo = async (formData: any) => {
        setGlobalErrorMessage("");
        formRef.current!.clearErrors();

        normalizeProfileFormsInFormData(account, formData);
        
        const submitData = JSON.parse(JSON.stringify(formData))

        let unsubscribeAll = false;
        let activeNewsletterPresent, activeProductReminderPresent;

        if (submitData.subscriptions) {
            activeNewsletterPresent = containsNewsletterSubscriptionInSubscribedStatus(submitData.subscriptions);
            activeProductReminderPresent = productReminderContext.productReminders.length > productReminderContext.productReminderIdsMarkedForDelete.length;
            
            unsubscribeAll = !activeNewsletterPresent && !activeProductReminderPresent;
        }
        try {
            if (submitData.subscriptions) {
                const optedOutOfAllProductReminders = productReminderContext.productReminderIdsMarkedForDelete.length > 0 && !activeProductReminderPresent;
                const optedOutOfAllNewsletters = account.subscriptions && containsNewsletterSubscriptionInSubscribedStatus(account.subscriptions) && !activeNewsletterPresent;

                if (optedOutOfAllProductReminders || optedOutOfAllNewsletters) {
                    let confirmed;
                    let option: ConfirmOverlayOption;
                    if (optedOutOfAllNewsletters && optedOutOfAllProductReminders) {
                        option = 'All';
                    } else if (optedOutOfAllNewsletters) {
                        option = 'Newsletters';
                    } else if (optedOutOfAllProductReminders) {
                        option = 'ProductReminders';
                    }

                    confirmed = await new Promise<boolean>((resolve) => {
                        setConfirmOverlayCallback({ promiseResolver: resolve, option: option })
                    });
                    if (!confirmed) {
                        return;
                    }
                }
            }
            
            const doPerformSetAccountInfo = async () => {
                const requestParams = {
                    ...replaceEmptyStringsWithNull(submitData), // clear data - replace empty strings with null
                    regToken: account.regToken
                }
                console.debug("SEND DATA ", requestParams);
                await gigyaWithPromise(gigya!.accounts.setAccountInfo, requestParams);
                if (!unsubscribeAll) {
                    await refreshAccount();
                    applyDataFromTabsAction({ type: "reset" });
                }
            };

            const performDeleteProductReminders = async () => {
                await deleteMarkedProductReminders();
                if (!unsubscribeAll) {
                    reloadProductReminders();
                }
            }

            await Promise.all([doPerformSetAccountInfo(), performDeleteProductReminders()]);

            if (!unsubscribeAll) {
                setSnackbarVisibility(true);
                setTimeout(() => setSnackbarVisibility(false), 5000); // Hide after 5 seconds

                showNextTabAfterSaving();
            } else {
                navigate("/unsubscribe-success");
            }
        }
        catch (error: unknown) {
            if (isGigyaErrorResponse(error)) {
                console.error("Error during setAccountInfo", error);
                const cdcErrorMessage = getMessage(`mpp.errors.${error.errorCode}.general` as MessageKey, error.errorDetails);
                setGlobalErrorMessage(cdcErrorMessage);

                if (error.validationErrors) {
                    for(const validationError of error.validationErrors) {
                        formRef.current!.setError(validationError.fieldName, {type: "custom", message: getMessage(`mpp.errors.${validationError.errorCode}.${validationError.fieldName}` as MessageKey, validationError.message)});
                    }
                }
            }
            else if (error instanceof ProductReminderDeletionFailedError) {
                console.error("Error during product reminder deletion", error);
                setGlobalErrorMessage(getMessage("mpp.errors.productreminders.deletion.failed"));
            }
            else {
                throw error;
            }
        }
        finally {
            setConfirmOverlayCallback(undefined);
        }
    }

    return (
        <div className="preference-center">
            <Meta titleProperty="mpc.page.title" />
            <UserCentricsComponent pageType={"Form Page"} pageName={getMessage("tracking.cpp.form")} primaryCategory={getMessage("tracking.cpp.form")} />
            <AdobeTagManagerComponent />
            <Header header={cmsData.components.header} />
            <div className="page__main" role="main">
                <div className="page__content">
                    <div className="container-full">
                        <div className="mod mod-overview-intro-2">
                            <div className="mod-overview-intro-2__media">
                                <CDNResponsiveImage
                                    className="img-responsive img-cover cq-dd-image"
                                    title={cmsData.components.preferenceCenter.welcomeimage.alt}
                                    alt={cmsData.components.preferenceCenter.welcomeimage.alt}
                                    sizes="(min-width: 1817px) 1817px, 100vw"
                                    src={cmsData.components.preferenceCenter.welcomeimage.src}
                                    widths={[768, 1024, 1817]} // no other sizes in germany: could be added "3000" for retina displays
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="mod mod-headline">
                            <h1 data-binding="true">
                                {getMessage("mpp.headline.pre")}
                                <span className="preSpaceContent">{account?.profile?.firstName && (` ${account?.profile?.firstName}`)}</span>
                                {getMessage("mpp.headline.post")}
                            </h1>
                        </div>
                        <div className="mod mod-copy">
                            <div className="rte">
                                <p>{getMessage("mpp.description")}</p>
                            </div>
                        </div>
                        <div ref={scrollAnchorRef} className="mod mod-headline">
                            <h2>{getMessage("mpp.subheadline")}</h2>
                        </div>
                        <div className="mod mod-tabs">
                            <div className="mod-tabs__nav">
                                <ScrollSlider items={Array.from(tabsStatus.values())}
                                    activeItem={tabStatusOfActiveTab}
                                    callbackFunction={onTabClickHandler}/>
                            </div>
                            <div className="mod-tabs__content">
                                {tabStatusOfActiveTab && <PreferenceTab ref={formRef}
                                    key={tabStatusOfActiveTab.tab.anchor}
                                    tabStatus={tabStatusOfActiveTab}
                                    accountSchema={gigyaSchema}
                                    globalErrorMessage={globalErrorMessage}
                                    saveForm={validateAndSend}
                                    dataToOverwrite={dataFromTabs}
                                />}
                                {confirmOverlayCallback && <ConfirmUnsubscribeAllOverlay confirmCallback={confirmOverlayCallback} />}
                                {showClickedOnDisabledTabDialog && <ClickedOnDisabledTabDialog confirmCallback={() => setShowClickedOnDisabledTabDialog(false)} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer footer={cmsData.components.footer} socialMediaBar={cmsData.components.socialmediabar}></Footer>
            <Snackbar headline={getMessage("mpp.successheadline")} description={getMessage("mpp.successdescription")} isActive={snackbarVisibility} hideHandler={()=>setSnackbarVisibility(false)} />
            <DataProcessingConsentedOptInDialog />
        </div>
    )
}



const ConfirmUnsubscribeAllOverlay = ( { confirmCallback } : { confirmCallback: ConfirmOverlayCallback } ) => {
    const { getMessage } = useI18n();

    const descriptions: Record<ConfirmOverlayOption, string> = {
        'Newsletters': getMessage("mpp.unsubscribeall.confirmNewsletters.description"),
        'ProductReminders': getMessage("mpp.unsubscribeall.confirmProductReminders.description"),
        'All': getMessage("mpp.unsubscribeall.confirmAll.description"),
    }

    return <ConfirmOverlay headline={getMessage("mpp.unsubscribeall.confirm.headline")}
        description={descriptions[confirmCallback.option]}
        confirmText={getMessage("mpp.unsubscribeall.confirm.confirm")}
        denyText={getMessage("mpp.unsubscribeall.confirm.deny")}
        confirmHandler={() => confirmCallback.promiseResolver(true)}
        denyHandler={() => confirmCallback.promiseResolver(false)} />
}

const ClickedOnDisabledTabDialog = ( { confirmCallback } : { confirmCallback: () => void } ) => {
    const { getMessage } = useI18n();

    return <ConfirmOverlay headline={getMessage("mpp.newsletter.subscribe.info.headline")}
        description={getMessage("mpp.newsletter.subscribe.info.description")}
        confirmText={getMessage("mpp.newsletter.subscribe.info.confirm")}
        confirmHandler={confirmCallback} />
}

const DataProcessingConsentedOptInDialog = () => {
    const {metaData} = useMetaData();
    const {account} = useAccount();
    const [optInVisibility, setOptInVisibility] = useState(false);

    useEffect(() => {
        //if the customer has no NL subscriptions, he'll not see the Opt-In-Dialog
        if (!hasNewsletterSubscriptions(account)) {
            setOptInVisibility(false);
            return;
        }

        let forceConsent = metaData?.marketingPreferenceCenter.forceConsent===true;
        let consented = account.data?.sfmc?.dataProcessingConsented===true;
        setOptInVisibility(forceConsent && !consented);

    }, [account, metaData?.marketingPreferenceCenter.forceConsent]);

    if (!optInVisibility) {
        return <></>;
    }
    return <Optin hideHandler={() => setOptInVisibility(false)} />
}