import {MessageKey, useI18n} from "_shared/hooks/I18n";
import {convertProfilePhonesSchemaNameToFieldName, normalizeProfileFormsInFormData, useAccountForm} from "../../hooks/GigyaSchema";
import {useAccount} from "../../Account";
import {gigyaWithPromise, useGigya} from "../../GigyaContext";
import {FormInput} from "../../_shared/components/FormInput";
import {SalutationComponent} from "../../_shared/components/SalutationComponent";
import {BirthdayComponent} from "../../marketing-preferences-page/components/BirthdayComponent";
import {replaceEmptyStringsWithNull} from "../../_shared/Utils";
import {PhoneNumberField} from "../../_shared/components/PhoneNumberField";
import { Breadcrumb } from "./shared-components/Breadcrumb";
import {useMemo, useState} from "react";
import { SuccessMessage } from "./shared-components/SuccessMessage";

export const PersonalData = () => {
    const formFields = [
        "data.salutation",
        "profile.firstName",
        "profile.lastName",
        "profile.birthDay",
        "profile.birthMonth",
        "profile.birthYear",
        "profile.phones[0].number",
        "profile.phones[0].type",
        "profile.phones[0].default"
    ];
    const { gigya } = useGigya();
    const { refreshAccount, account} = useAccount();
    const { formItemDetails, registerFormInput, setError, setValue, handleSubmit } = useAccountForm(formFields);
    const { getMessage } = useI18n();
    const [showSuccess, setShowSuccess] = useState(false);

    const breadCrumbData = useMemo(() =>
        ([
            {
                label: getMessage("ap.startpage"),
                link: "/"
            },
            {
                label:getMessage("ap.account.personaldata.headline"),
                isCurrent: true
            }
        ]), [getMessage]
    );

    if (!gigya) {
        return <></>
    }

    let bm_fid= formItemDetails.get("profile.birthMonth")!;
    let bd_fid= formItemDetails.get("profile.birthDay")!;
    let by_fid= formItemDetails.get("profile.birthYear")!;

    const handleUpdate = async (formData: any) => {
        setShowSuccess(false);
        formData = replaceEmptyStringsWithNull(formData);
        normalizeProfileFormsInFormData(account, formData);
        try {
            await gigyaWithPromise(gigya.accounts.setAccountInfo, formData)
            .then(async function() {
                await refreshAccount();
                setShowSuccess(true);
            }).catch(function(err) {
                err?.validationErrors?.forEach((validationError: any, _idx: number) => {
                    if (validationError?.fieldName) {
                        const convertedName = convertProfilePhonesSchemaNameToFieldName(validationError.fieldName);
                        setError(convertedName, {
                            type: "pattern",
                            message: getMessage(`ap.errors.${convertedName}.${validationError.errorCode}` as MessageKey, validationError.message),
                        });
                    }
                });
            });
        } catch(errorResponse) {
            throw errorResponse;
        }
    }

    return (
        <div id="personaldata">
            <Breadcrumb items={breadCrumbData} />
            <SuccessMessage show={showSuccess} message={getMessage("ap.account.personaldata.success")} />
            <h2>{getMessage("ap.account.personaldata.headline")}</h2>
            <form onSubmit={handleSubmit(handleUpdate)} autoComplete="on">
                <fieldset>
                    <SalutationComponent registerInput={registerFormInput(formItemDetails.get("data.salutation")!)} />
                    <FormInput {...registerFormInput(formItemDetails.get("profile.firstName")!)}/>
                    <FormInput {...registerFormInput(formItemDetails.get("profile.lastName")!)}/>
                    <BirthdayComponent setValue={setValue}
                                       value_day={bd_fid?.value} value_month={bm_fid?.value} value_year={by_fid?.value}
                                       dayFormRegister={registerFormInput(bd_fid)} monthFormRegister={registerFormInput(bm_fid)} yearFormRegister={registerFormInput(by_fid)} />
                    <PhoneNumberField
                        registerInput={registerFormInput(formItemDetails.get("profile.phones[0].number")!)}
                        typeRegisterInput={registerFormInput(formItemDetails.get("profile.phones[0].type")!)}
                        defaultRegisterInput={registerFormInput(formItemDetails.get("profile.phones[0].default")!)}
                    />
                </fieldset>
                <button type="submit" className="btn btn--primary btn-full">{getMessage("global.button.save")}</button>
            </form>
        </div>
    )
}