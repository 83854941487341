import {AccountFormItemDetail, RegisterFormInputFunction} from "../../hooks/GigyaSchema";
import {ChangeEvent, useCallback, useEffect, useId, useReducer} from "react";
import {useAccount} from "../../Account";
import {useGigya} from "../../GigyaContext";
import {UseFormReturn} from "react-hook-form";
import {ErrorField} from "../../_shared/components/ErrorField";
import {useI18n} from "../../_shared/hooks/I18n";
import {useMetaData} from "../../MetaDataContext";
import {SelectList, SelectListCallbackParameters, SelectListOption} from "_shared/components/SelectList";
import {Cms} from "../../CmsContext";
import { FormInput } from "_shared/components/FormInput";

export type FavoriteStoreComponentProp = {
    registerFormInput: RegisterFormInputFunction<any>,
    formItemDetails: Map<string, AccountFormItemDetail<any>>,
    useFormReturn: UseFormReturn<any>,
    cmsInputField: Cms.ProfileFieldFavoriteStore
}

interface FavoriteStoreEntry {
    id: string,
    uiText: string
}

interface FavoriteStoreResponse {
    status: string,
    retailStores: FavoriteStoreEntry[]
}

interface FavoriteStoreComponentState {
    valid: boolean,
    storeId?: string,
    storeName?: string,
    storeListVisible: boolean,
    displayValue: string,
    storeList: SelectListOption[]
}

interface SelectFavoriteStoreAction {
    type: "selectStore",
    storeId: string,
    storeName: string
}

interface ClearFavoriteStoreAction {
    type: "clear",
}

interface OpenStoreListAction {
    type: "openStoreList",
}

interface SearchStoreAction {
    type: "searchStore",
    searchTerm: string
}

interface SearchResultsReceivedAction {
    type: "searchStoreResultsReceived",
    searchResults: SelectListOption[]
}

interface StopStoreSearchAction {
    type: "stopStoreSearch"
}

type FavoriteStoreAction = SelectFavoriteStoreAction | ClearFavoriteStoreAction | OpenStoreListAction | SearchStoreAction | SearchResultsReceivedAction | StopStoreSearchAction;

type FavoriteStoreComponentStateReducerFunction = (selection: FavoriteStoreComponentState, action: FavoriteStoreAction) => FavoriteStoreComponentState;

const EMPTY_STATE: FavoriteStoreComponentState = {
    valid: true,
    displayValue: "",
    storeListVisible: false,
    storeList: []
};

export const FavoriteStoreComponent = ({registerFormInput, formItemDetails, useFormReturn, cmsInputField} : FavoriteStoreComponentProp) => {

    const { setValue, setError, clearErrors, formState: { errors } } = useFormReturn;
    const fsFormItemDetails = formItemDetails.get(cmsInputField.cdcProperty)!;

    const [state, dispatchState] = useReducer(favoriteStoreReducer, { ...EMPTY_STATE, storeId: fsFormItemDetails.value} );

    const errorFieldId = useId();

    const {metaData} = useMetaData();
    const {account} = useAccount();
    const {isGigyaReady} = useGigya();
    const {getMessage} = useI18n();

    const callApi = useCallback(async (currentStoreId:string) : Promise<FavoriteStoreEntry[]> => {
        if (!metaData || !currentStoreId) {
            return [];
        }
        return fetch(metaData.restApi + "/rest/v1/retailstores/search?" + new URLSearchParams({
            "q": currentStoreId,
        }), {
            method: 'GET',
            headers:{},
        })
        .then((rawResponse) => rawResponse.json())
        .then(json => json as FavoriteStoreResponse)
        .then(response => response.retailStores);
    }, [metaData]);

    useEffect(() => {
        if (!isGigyaReady() || !metaData || !account) {
            return;
        }

        const setCurrentStoreId = async (currentStoreId: string) => {
            const response = await callApi(currentStoreId);
            if (response.length === 1) {
                let rs = response[0];
                dispatchState({
                    type: "selectStore",
                    storeId: rs.id,
                    storeName: rs.uiText
                })
            }
        }

        const currentStoreId = fsFormItemDetails.value;
        if (currentStoreId) {
            setCurrentStoreId(currentStoreId).catch(console.error);
        }
    }, [account, metaData, isGigyaReady, callApi, fsFormItemDetails.value, dispatchState]);

    useEffect(() => {
        if (state.valid) {
            clearErrors("storeNotSelected");
        } else {
            setError("storeNotSelected", {type: "custom", message: getMessage("mpp.favorite-store.not-selected")});
        }
    }, [state, clearErrors, setError, getMessage]);

    useEffect(() => {
        setValue(fsFormItemDetails.field, state.storeId ?? "");
    }, [state.storeId, fsFormItemDetails.field, setValue]);

    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        let value =  event.target.value;

        if (!metaData) {
            return;
        }

        if(!value || value === "") {
            dispatchState({
                type: "clear"
            });
            return;
        }

        dispatchState({
            type: "searchStore",
            searchTerm: value
        });
        
        // print list
        const response = await callApi(value);
        const ns:SelectListOption[] = [];
        response.forEach((rs)=>{
            ns.push({value:rs.id, label: rs.uiText});
        });
        dispatchState({
            type: "searchStoreResultsReceived",
            searchResults: ns
        });
    };

    const onSelect = function ({selectedOption} : SelectListCallbackParameters) {
        clearErrors("storeNotSelected");

        dispatchState({
            type: "selectStore",
            storeId: selectedOption.value,
            storeName: selectedOption.label
        })
    }

    const registerReturn = registerFormInput(fsFormItemDetails);
    let {errorDetails:fsErrorDetails} = registerReturn;
    const hasErrors = !!fsErrorDetails || !!errors.storeNotSelected;

    return(
        <>
        <fieldset
            onBlur={() => dispatchState({ type: "stopStoreSearch" })}
            onFocus={() => dispatchState({ type: "openStoreList" })}
        >
            <label className={`textfield ${hasErrors ? "has-error" : ""}`}>
                <input type="text"
                    className={`textfield__control ${hasErrors ? "error" : ""}`}
                    placeholder={cmsInputField.empty}
                    onChange={onChange}
                    aria-errormessage={hasErrors ? errorFieldId : undefined}
                    aria-invalid={hasErrors ? true : undefined}
                    value={state.displayValue}
                />
                <span className="textfield__label">{cmsInputField.label}</span>
                {state.storeListVisible && <SelectList options={state.storeList} callback={onSelect} />}
                
                <ErrorField id={errorFieldId + "storeNotSelectedError"} field={errors.storeNotSelected} />
                <ErrorField id={errorFieldId} field={fsErrorDetails} />
            </label>
        </fieldset>
        <FormInput type="hidden" {...registerReturn} value={state.displayValue}/>
        </>
    );
}

const favoriteStoreReducer: FavoriteStoreComponentStateReducerFunction = (state, action): FavoriteStoreComponentState => {
    switch (action.type) {
        case "clear":
            return EMPTY_STATE;
        case "selectStore":
            return {
                ...state,
                displayValue: action.storeName,
                storeListVisible: false,
                valid: true,
                storeId: action.storeId,
                storeName: action.storeName
            };
        case "openStoreList":
            return {
                ...state,
                storeListVisible: state.storeList.length > 0
            }
        case "searchStore":
            return {
                ...state,
                displayValue: action.searchTerm,
                valid: false
            };
        case "searchStoreResultsReceived":
            return {
                ...state,
                storeList: action.searchResults,
                storeListVisible: action.searchResults.length > 0
            };
        case "stopStoreSearch":
            return {
                ...state,
                storeListVisible: false,
                displayValue: state.storeName ?? "",
                valid: true
            };
    }
}