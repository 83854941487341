import { MutableRefObject, RefCallback } from "react";

export type DeepPartial<T> = T extends object ? {
    [P in keyof T]?: DeepPartial<T[P]>;
} : T;

export const fetchWithErrorHandling = async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
    const response = await fetch(input, init);
    await handleFetchResponseErrors(response)
    return response;
}

export const handleFetchResponseErrors = async (response: Response) => {
    if (!response.ok) {
        const errorHint = `Response had status ${response.status}`;
        console.error(errorHint, response);
        const json = await response.json();
        if ("message" in json) {
            throw new Error(errorHint + json.message);
        }

        throw new Error(errorHint);
    }
}
/**
 * Returns a property given a specified query.
 * Example:
 * sampleObj = {
 *   property1: {
 *      childProperty: 5
 *   }
 * }
 * 
 * queryObjectProperty(sampleObj, "property1.childProperty") will return 5
 * 
 * @param {*} obj 
 * @param {*} query 
 * @returns undefined if the property or one of its parents doesn't exist
 */
export const queryObjectProperty = (obj: any, query: string): any => {
    let bracketIndex = query.indexOf("[");
    if (bracketIndex >= 0) {
        const prefix = query.substring(0, bracketIndex)
        const subObj = queryObjectProperty(obj, prefix);
        if (subObj) {
            return extractArrayDataFromField(prefix, query, subObj);
        }
        return undefined;
    }
    return query.split(".").reduce((subObj, fieldPart) => subObj !== undefined ? subObj[fieldPart] : undefined, obj);
}

/**
 * Removes the array parts ([...]) from a string
 *
 * @param v {string} the value
 */
export const removeArrayPartFromString = (v: string) => {
    return v.replace(/\[\d+\]/g, "");
}

/**
 * Extract array data from an object.
 *
 * @param prefix {string} the query prefix with the array
 * @param query {string} the full query
 * @param dataArray {any[]} the array object
 */
export const extractArrayDataFromField = (prefix: string, query: string, dataArray: any[]): any => {
    function escapeRegExp(s: string) {
        return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    let matcher = new RegExp(escapeRegExp(prefix) + "\\[(\\d+)\\]\\.(.*)").exec(query);
    if (matcher) {
        let index = +matcher[1];
        let postfix = matcher[2];
        let obj = dataArray[index];
        return queryObjectProperty(obj, postfix);
    }else {
        throw new Error("Matcher error");
    }
}

/**
 * Returns a function that can be handed over to react as a ref.
 * Ensures, that // what?
 * @param {*} refFunc A ref compatible function - recieves the current value
 * @param {*} ref Result returned by React.useRef
 * @returns 
 */
export const copyRefFunc = <T>(refFunc: RefCallback<T>, ref: MutableRefObject<T>) => (e : T) => {
    refFunc(e);
    ref.current = e;
}

/**
 * Replace all properties with empty string with null.
 * @param object object to modify
 */
export const replaceEmptyStringsWithNull = function (object: any) {
    if (typeof object === "string" && object === "") {
        return null;
    }else {
        for (let key in object) {
            if (typeof object[key] === "string" && object[key] === "") {
                object[key] = null;
            } else if (typeof object[key] === "object") {
                object[key] = replaceEmptyStringsWithNull(object[key]);
            }
        }
    }

    return object;
}