import { FatalErrorPage } from "central-page/FatalErrorPage";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useI18n } from "./_shared/hooks/I18n";
import { useMetaData } from "./MetaDataContext";

const UI_ENDPOINT = process.env.REACT_APP_UI_ENDPOINT;

export namespace Cms {

    export type SubscriptionMap = {
        [id: string]: Subscription
    }

    export interface Subscription {
        name: string,
        descriptions: string[],
        icon: string,
        displayStart: Date,
        displayEnd: Date
    }

    export interface Image {
        alt: string,
        src: string,
    }

    export interface AbstractLink {
        id: string,
        openblank: boolean,
        url: string
    }

    /**
     * Link with icon.
     */
    export interface IconLink extends AbstractLink {
        /**
         * An icon key, not an url
         */
        icon: string,
        alt: string
    }

    /**
     * Link with text
     */
    export interface TextLink extends AbstractLink {
        label: string
    }

    /**
     * Link with text and additional description
     */
    export interface TextLinkWithDescription extends TextLink {
        description: string
    }

    /**
     * Link as label (w/o URL)
     */
    export interface LabeledLink {
        label: string
    }

    export interface LinkBox extends TextLinkWithDescription {
        image: string,
        buttonlabel: string
    }

    export interface ShoppingFrequenciesEmptyValue {
        name: string
    }

    export interface ShoppingFrequencyEntry {
        id: string,
        name: string
    }

    export interface ShoppingFrequencies {
        name: string,
        emptyValue: ShoppingFrequenciesEmptyValue,
        values: ShoppingFrequencyEntry[]
    }

    export interface CountryList {
        code: string
    }
    export interface SalutationData {
    }

    export interface ProfileFieldMapOption {
        id: string,
        label: string
    }

    export interface ProfileField {
        component: string,
        cdcProperty: string,
        label: string,
        readonly?: boolean,
        data: ShoppingFrequencies | CountryList | SalutationData
    }

    export interface ProfileFieldSingle extends ProfileField {
        cdcProperty: string,
        empty: string
    }

    export interface ProfileFieldOptions extends ProfileFieldSingle {
        options: ProfileFieldMapOption[]
    }
    export interface ProfileFieldOptionList extends ProfileFieldSingle {
        options: string[]
    }

    export interface ProfileFieldSalutation extends ProfileFieldOptions{}
    export interface ProfileFieldShoppingFrequency extends ProfileFieldOptions{}
    export interface ProfileFieldCountry extends ProfileFieldOptionList{}
    export interface ProfileFieldFavoriteStore extends ProfileFieldSingle {}
    export interface ProfileFieldBirthday extends ProfileField {
        cdcPropertyDay: string,
        cdcPropertyMonth: string,
        cdcPropertyYear: string
    }

    export interface Tile {
        schemaPaths: string[],
        label: string,
        imageUrl: string,
    }

    export interface RadioTile extends Tile {
        description: string,
        icon: string
    }

    export interface AbstractBaseTiles {
        type: string
    }

    export interface Tiles extends AbstractBaseTiles {
        label: string,
        description: string,
        tiles: Tile[]
    }

    export interface RadioTiles extends Tiles {
        tiles: RadioTile[]
    }

    export interface SubscriptionTiles extends Tiles {
        headline?: string,
        subscriptions: SubscriptionMap,
    }

    export interface ProfileTiles extends Tiles {
        fields: ProfileField[],
        shoppingFrequencies: ShoppingFrequencies,
        salutationVisible?: boolean
    }

    export interface Tab {
        components: AbstractBaseTiles[],
        title?: string,
        description?: string,
        anchor: string,
        label: string,
        accessWithoutNewsletterSubscription?: boolean
    }

    export interface PreferenceCenterComponent {
        tabs: Tab[],
        welcomeimage: Image,
        unsubscribeimage: Image,
        doubleoptinimage: Image,
        subscribeLink: Image
    }

    export interface SocialMediaBar {
        headline: string,
        socialmedialinks: IconLink[]
    }

    export interface AccountPageComponent {
        accountLinks: TextLinkWithDescription[]
    }

    export interface FooterArea {
        headlinelink: TextLink,
        defaultlinks: TextLink[],
        cookieconsentlink: LabeledLink
    }

    export interface Header {
        logo: Image,
        usptext: string
    }

    export interface SimpleFooter {
        footerLinks: TextLink[]
    }

    export interface SsoClientsOverview {
        items: LinkBox[]
    }

    export interface CmsComponents {
        preferenceCenter: PreferenceCenterComponent
        header: Header,
        footer: FooterArea[],
        socialmediabar: SocialMediaBar,
        accountPage: AccountPageComponent,
        simpleFooter: SimpleFooter
        SsoClientsOverview: SsoClientsOverview
    }

    export interface CmsData {
        components: Cms.CmsComponents
    }
}

interface CmsContext {
    cmsData?: Cms.CmsData
}

const cmsDataContext = createContext<CmsContext>({
    cmsData: undefined
});


const fetchCmsData = async (currentLanguage: string, cmsId?: string): Promise<Cms.CmsData> => {
    if (process.env.REACT_APP_CMSDATA) {
        const response = await fetch(process.env.REACT_APP_CMSDATA);
        return await response.json();
    }
    const url = (cmsId && currentLanguage) ? `${UI_ENDPOINT}/cms/${cmsId}/${currentLanguage}.json` : `${UI_ENDPOINT}/cms/default.json`;
    console.log("Load CMS: " + cmsId + " " + currentLanguage + " with url " + url)
    const response = await fetch(url);
    return await response.json();
}

export const CmsDataProvider = ({children}: {children: ReactNode}) => {
    const {currentLanguage} = useI18n();
    const {metaData} = useMetaData();

    const [cmsData, setCmsData] = useState<Cms.CmsData>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        let run = true;
        if (metaData && currentLanguage) {
            fetchCmsData(currentLanguage, metaData.cms?.id)
                .then(data => run && setCmsData(data))
                .catch((e) => {
                    console.log("cms data fetch failed.", e)
                    setError("cms data fetch failed.")
                });
        }
        return () => {
            run = false;
        }
    },[metaData, currentLanguage]);
   
    const { Provider } = cmsDataContext;

    const uiData = useMemo(() => {
        return {
            cmsData: cmsData
        }
    }, [cmsData]);

    if (error) {
        return <FatalErrorPage message={error} />
    }
    return(
        <Provider value={uiData}>
            {children}
        </Provider>
    )
}

export const useCmsData = () => useContext(cmsDataContext);